/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


ion-radio::part(container) {
    width: 20px;
    height: 20px;
    
    border-radius: 8px;
    border: 2px solid #ddd;
}
  
ion-radio::part(mark) {
    background: none;
    transition: none;
    transform: none;
    border-radius: 0;
}
  
ion-radio.radio-checked::part(container) {
    background: #eb445a;
    border-color: transparent;
}
  
ion-radio.radio-checked::part(mark) {
    width: 6px;
    height: 10px;
  
    border-width: 0px 2px 2px 0px;
    border-style: solid;
    border-color: #fff;
  
    transform: rotate(45deg);
}

.centerContent {
    display: flex; 
    justify-content: center; 
    align-items: center;
}

.bottonContent {
    display: flex; 
    justify-content: center; 
    align-items: flex-end;
}

.paddingLeftRight {
    margin-right: 16px; 
    margin-left: 16px;
}

.capitalizeText {
    text-transform:capitalize;
}

ion-label{
    text-transform:capitalize;
}

.pintConnerLeft {
    position: absolute;
    top: 0px; 
    left: 0px;
}

.pintConnerRight {
    position: absolute;
    top: 0px; 
    right: 0px;
}

.pintLogoConnerRight {
    position: absolute;
    top: 4%; 
    right: 4%;
}
.superExtraSmallText {
    font-size: 8px;
}

.extraSmallText {
    font-size: 10px;
}

.smallText {
    font-size: 12px;
}

.logoSize {
    height: 40px;
    width: 40px;
}

.mediumFontSize {
    font-size: medium;
}

.shopLogo {
    width: 100px;
    height: 100px; 
    border-radius: 100%;
}

.shopLogoSmall {
    width: 60px;
    height: 60px; 
    border-radius: 100%;
}

.cookie-consent-modal-class {
    &::part(content) {
      height: 300px;
    }
}

.colorBox {
    height: 20px;
    width: 20px;
}

.showOnSmallScreen {
    display: none;
}

@media screen and (max-width: 400px) {
    // .shopLogoSmall {
    //     display: none;
    // }

    .shopLogoSmall {
        width: 40px;
        height: 40px; 
        border-radius: 100%;
    }

    .hideOnSmallSceen {
        display: none;
    }
    .showOnSmallScreen {
        display:flex;
    }

}

@media screen and (max-width: 280px) {
    .logoSize {
        height: 30px;
        width: 30px;
    }

    .action-sheet-group.sc-ion-action-sheet-ios {
        overflow: auto;
    }
}

table {
    border-collapse: collapse;
    margin: 20px;
}

.keyboardSpace {
    height: 400px;
}

.services {
    tr {
      border-bottom: 1px solid black;
      border-width: thin;
    }
  
    td {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 5px;
    }
  }

